import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class ChartsController extends Controller {
  static defaultOptions = { responsive: true, maintainAspectRatio: false }
  static targets = [
    'avaliacoesPositivas',
    'avaliacoesNegativas',
    'avaliacoesNeutras',
    'gauge',
    'grayBox'
  ]

  initialize() {
    this.jsonData = this.getJSONFromDataAttribute(this.element, 'chart-data')
    this.defaultSector = 'TOTAL'
    this.currentData = {}
    this.setCurrentData(this.defaultSector)
    this.barData1 = this.jsonData
    this.sectorsData = { ...this.jsonData }
    this.lineChart = null
    delete this.sectorsData.TOTAL
    console.log(this.currentData)
  }

  connect() {
    this.render();
  }

  render() {
    const barChart1 = document.getElementById('bar-chart-1')
    if (barChart1) {
      const barChart1Chart = this.createBarChart(barChart1, 'ces')
      barChart1.addEventListener('click', (evt) => {
        this.clickHandler(evt, barChart1Chart)
      })

      barChart1.addEventListener('mouseout', (evt) => {
        this.clickHandler(evt, barChart1Chart)
      })
    }

    const barChart2 = document.getElementById('bar-chart-2')
    if (barChart2) {
      const barChart2Chart = this.createBarChart(barChart2, 'csat')
      barChart2.addEventListener('click', (evt) => {
        this.clickHandler(evt, barChart2Chart)
      })
      barChart2.addEventListener('mouseout', (evt) => {
        this.clickHandler(evt, barChart2Chart)
      })
    }

    const lineChart1 = document.getElementById('line-chart-1')
    if (lineChart1) {
      this.lineChart1 = this.createLineChart(lineChart1)
    }

    this.setNumbers()
  }

  setNumbers() {
    this.setAvaliacoes()
    this.setGauges()
    this.setGrayBoxes()
  }

  jsonData() {
    return this.jsonData
  }

  availableSectors() {
    return Object.keys(this.jsonData())
  }

  setCurrentData(key) {
    this.currentData = this.jsonData[key]
  }

  getLabelsFromSectors() {
    return Object.keys(this.sectorsData)
  }

  getMetricsFromSectors(metric) {
    return Object.values(this.sectorsData).map(obj => obj[`valor_${metric}`])
  }

  setAvaliacoes() {
    this.avaliacoesPositivasTarget.textContent = this.currentData.avaliacoes_positivas
    this.avaliacoesNegativasTarget.textContent = this.currentData.avaliacoes_negativas
    this.avaliacoesNeutrasTarget.textContent = this.currentData.avaliacoes_neutras
  }

  setGrayBoxes() {
    const values = {
      "Qtd. Casos": this.currentData.qtd_casos,
      "Qtd. Clientes": this.currentData.qtd_clientes,
      "Qtd. Disparos": this.currentData.qtd_disparos,
      "Qtd. Respostas": this.currentData.qtd_respostas,
      "Taxa de Resposta": this.currentData.taxa_de_resposta.replace('%','')
    }

    this.grayBoxTargets.forEach((box, i) => {
      let titleTag = box.querySelector('h4')
      let valueTag = box.querySelector('.number-value')
      let symbolTag = box.querySelector('.symbol')
      let valuesArray = Object.entries(values)

      titleTag.textContent = valuesArray[i][0] || 'N/A'
      valueTag.value = valuesArray[i][1] || 'N/A'
      if(valuesArray[i][0] == 'Taxa de Resposta') { symbolTag.textContent = '%' }
    })
  }

  setGauges() {
    const values = [this.currentData.valor_ces, this.currentData.valor_csat]

    this.gaugeTargets.forEach((gauge, i) => {
      let gaugeValue = gauge.querySelector('.value')
      let value = values[i]
      let valueFloat = parseFloat(value)?.toFixed(1)
      let degree = (180 * valueFloat / 5)
      gaugeValue.textContent = value || 'N/A'

      gauge.style.setProperty('--rotation', `${degree}deg`)
    })
  }

  getValuesFromData(key) {
    let data = []
    try {
      data = Object.values(this.currentData[key])
    } catch (error) { console.log(`no data available for ${key}`) }
    return data
  }

  createLineChart(element) {
    const datasets = this.getJSONFromDataAttribute(element, 'datasets')
    const data1 = this.getValuesFromData('evolucao_ces')
    const data2 = this.getValuesFromData('evolucao_csat')

    return new Chart(element, {
      type: 'line',
      data: {
        labels: Object.keys(this.currentData.evolucao_csat),
        datasets: [
          {
            label: datasets[0].label,
            data: data1,
            borderWidth: 2,
            borderColor: '#0E5FFA',
            pointRadius: 1
          },
          {
            label: datasets[1].label,
            data: data2,
            borderWidth: 2,
            borderColor: '#FF56FF',
            pointRadius: 1
          }
        ]
      },
      options: {
        animation: {
          duration: 2000
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 5,
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    });
  }

  createBarChart(element, metric) {
    return new Chart(element, {
      type: 'bar',
      data: {
        labels: this.getLabelsFromSectors(),
        datasets: [{
          label: 'Média por Setor',
          data: this.getMetricsFromSectors(metric),
          borderWidth: 1,
          borderRadius: 5,
          backgroundColor: '#0E5FFA',
          hoverBackgroundColor: '#F43f5E'
        }]
      },
      options: {
        animation: {
          duration: 2000
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 5,
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    });
  }

  clickHandler(evt, myChart) {
    const points = myChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const label = myChart.data.labels[firstPoint.index];
      const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

      myChart.setActiveElements([{datasetIndex: 0, index: firstPoint.index}])
      this.setCurrentData(label)
    } else {
      this.setCurrentData(this.defaultSector)
    }

      this.setNumbers()

      const data = [this.getValuesFromData('evolucao_ces'), this.getValuesFromData('evolucao_csat')]

      myChart.update()
      this.lineChart1.data.datasets.forEach((dataset, i) => {
        dataset.data = data[i]
      })
      this.lineChart1.data.labels = Object.keys(this.currentData.evolucao_csat)
      this.lineChart1.update()
  }


  getJSONFromDataAttribute(element, attribute) {
    return JSON.parse(element.getAttribute(`data-${attribute}`))
  }
}
