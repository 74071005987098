import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class ReclameAquiController extends Controller {
  static defaultOptions = { responsive: true, maintainAspectRatio: false }
  static targets = [
    'avaliacoesPositivas',
    'avaliacoesNegativas',
    'avaliacoesNeutras',
    'blueBox',
    'grayBox'
  ]

  initialize() {
    this.jsonData = this.getJSONFromDataAttribute(this.element, 'chart-data')
    this.defaultSector = 'TOTAL'
    this.currentData = {}
    this.setCurrentData(this.defaultSector)
    console.log(this.jsonData)
  }

  connect() {
    this.render();
  }

  render() {
    const lineChart1 = document.getElementById('line-chart-1')
    if (lineChart1) { this.createLineChart(lineChart1) }

    // this.setAvaliacoes()
    this.setGrayBoxes()
    this.setBlueBox()
  }

  jsonData() {
    return this.jsonData
  }

  availableSectors() {
    return Object.keys(this.jsonData())
  }

  setCurrentData(key) {
    this.currentData = this.jsonData[key]
  }

  setAvaliacoes() {
    this.avaliacoesPositivasTarget.textContent = this.currentData.avaliacoes_positivas
    this.avaliacoesNegativasTarget.textContent = this.currentData.avaliacoes_negativas
    this.avaliacoesNeutrasTarget.textContent = this.currentData.avaliacoes_neutras
  }

  setGrayBoxes() {
    const values = {
      "Qtd. Casos": this.currentData.qtd_casos,
      "Qtd. Repostas": this.currentData.qtd_respostas,
      "Taxa de Resposta": this.currentData.taxa_de_resposta
    }

    this.grayBoxTargets.forEach((box, i) => {
      let titleTag = box.querySelector('h4')
      let valueTag = box.querySelector('.number-value')
      let valuesArray = Object.entries(values)

      titleTag.textContent = valuesArray[i][0] || 'N/A'
      valueTag.value = valuesArray[i][1] || 'N/A'
    })
  }

  setBlueBox() {
    let titleTag = this.blueBoxTarget.querySelector('h4')
    let valueTag = this.blueBoxTarget.querySelector('h2')

    titleTag.textContent = 'Reputação' || 'N/A'
    valueTag.textContent = this.currentData.valor_reputacao || 'N/A'
  }

  getValuesFromData(key) {
    let data = []
    try {
      data = Object.values(this.currentData[key])
    } catch (error) { console.log(`no data available for ${key}`) }
    return data
  }

  createLineChart(element) {
    const data1 = this.getValuesFromData('evolucao_reputacao')

    new Chart(element, {
      type: 'line',
      data: {
        labels: Object.keys(this.currentData.evolucao_reputacao),
        datasets: [
          {
            label: 'Reputação',
            data: data1,
            borderWidth: 4,
            borderColor: '#0E5FFA',
            pointRadius: 5
          }
        ]
      },
      options: {
        animation: {
          duration: 2000
        },
        scales: {
          y: {
            ticks: {
              stepSize: 1
            }
          }
        }
      }
    });
  }

  getJSONFromDataAttribute(element, attribute) {
    return JSON.parse(element.getAttribute(`data-${attribute}`))
  }
}
