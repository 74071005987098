// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"

import mrujs from "mrujs"
import * as Turbo from "@hotwired/turbo"
import { RollingNumber } from "./rolling-number"

window.RollingNumber = RollingNumber
// window.customElements.define("layflags-rolling-number", RollingNumber);

window.Turbo = Turbo
mrujs.start({
  errorRenderer: "turbo"
})

require('./modal_form')
