import KioskBoard from 'kioskboard';

  keysArray = [
    { "0": "Q", "1": "W", "2": "E", "3": "R", "4": "T", "5": "Y", "6": "U", "7": "I", "8": "O", "9": "P" },
    { "0": "A", "1": "S", "2": "D", "3": "F", "4": "G", "5": "H", "6": "J", "7": "K", "8": "L" },
    { "0": "@", "1": "Z", "2": "X", "3": "C", "4": "V", "5": "B", "6": "N", "7": "M", "8": '.' }
  ]

KioskBoard.init({
  keysArrayOfObjects: keysArray,
  keysNumpadArrayOfNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '@'],
  keysSpecialCharsArrayOfStrings: ['.', '@', '-', '_'],
  language: 'en',
  theme: 'light',
  capsLockActive: false,
  allowRealKeyboard: true,
  allowMobileKeyboard: true,
  cssAnimations: true,
  keysAllowSpacebar: false,
  keysFontFamily: 'Montserrat, sans-serif',
})

document.addEventListener('turbo:load', () => {
  document.querySelector('form#users')?.addEventListener('ajax:error', (event) => {
    const statusCode = event.detail.response.status
    if (statusCode >= 500) {
      setError('Houve um erro ao tentar enviar dados. Tente novamente mais tarde.')
    } else if (statusCode >= 400 && statusCode < 500) {
      event.detail.fetchResponse.json().then((json) => {
        setError(json.message)
      })
    }
  })

  function setError(text) {
    const errorDisplay = document.querySelector('form#users p#errors')
    errorDisplay.textContent = text
  }

  document.querySelector('form#users')?.addEventListener('ajax:success', (event) => {
    if (event.detail.response.redirected) {
      window.location.href = event.detail.response.url
    }
  })

  const targetNode = document.getElementById("page-intro");
  const keyboardId = '#KioskBoard-VirtualKeyboard'

  // Options for the observer (which mutations to observe)
  const config = { childList: true, subtree: false };

  // Callback function to execute when mutations are observed
  const callback = (mutationList, observer) => {
    mutationList.forEach((mutation) => {
      const addedNodes = Array.from(mutation.addedNodes)
      const removedNodes = Array.from(mutation.removedNodes)

      addedNodes.forEach((node) => {
        if (node.nodeType === 1 && node.matches(keyboardId)) {
          setTimeout(() => {
            document.getElementById('authentication-modal').style.setProperty('transform', 'translateY(-15%)')
          }, 130)
        }
      })

      removedNodes.forEach((node) => {
        if (node.nodeType === 1 && node.matches(keyboardId)) {
          setTimeout(() => {
            document.getElementById('authentication-modal').style.setProperty('transform', 'translateY(0)')
          }, 130)
        }
      })
    })
  }

  // Create an observer instance linked to the callback function
  if (targetNode) {
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  }

  const keyboardInput = document.querySelector('.js-kioskboard-input')
  if (keyboardInput){ KioskBoard.run('.js-kioskboard-input') }
})
